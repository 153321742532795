import { TbX } from "solid-icons/tb";
import { Show, createSignal } from "solid-js";
import { StButton } from "~/components/buttons";

export const SmartchatBanner = () => {
  const [show, setShow] = createSignal(true);
  return (
    <Show when={show()}>
      <div class="bg-indigo-800 text-white relative">
        <div class="mx-auto max-w-screen-xl px-10 py-3 text-center">
          Welcome to our brand-new beta platform! Want our original SmartChat™?{" "}
          <a
            href="https://smartchat.storytell.ai"
            target="_blank"
            rel="noreferrer"
            class="underline underline-offset-2"
          >
            Access it here.
          </a>
        </div>

        <StButton
          label="Close"
          simple
          class="absolute right-2 top-2 text-white hover:bg-white/20"
          icon={TbX}
          onClick={() => setShow(false)}
        />
      </div>
    </Show>
  );
};
